import React, { useContext, useEffect } from 'react'
import {
	ContentRow,
	ContentSideBarWrapper,
	Divider,
	SmallPageSectionDivider,
} from '../../reusable_components/layout/PageComponents'
import { theme } from '../../../utils/theme'
import CourseCardsWrapper from '../../reusable_components/course_cards/CourseCardsWrapper'
import { HostPageReviewList } from '../../reusable_components/reviews/ReviewList'
import styled from 'styled-components'
import ContentSlider from '../../courses/show/ContentSlider'
import FollowButton from '../../reusable_components/buttons/FollowButton'
import NonResponsiveImage from '../../reusable_components/responsive/NonResponsiveImage'
import { useTranslation } from 'react-i18next-new'
import { Markdown } from '../../reusable_components/Typography'
import { BeaconAdjuster } from '../../../utils/helpScoutHelper'
import ContentSideBarContext from '../../../contexts/content_side_bar/ContentSideBarContext'
import { ProfilePicture, StyledProfilePicture } from '../../reusable_components/other/CourseComponents'
import DatesSection from './DatesSection'
import { VideoFormContainer } from '../../static_pages/ExpertInfo'
import Facebook from '../../reusable_components/svg_icons/Facebook'
import Instagram from '../../reusable_components/svg_icons/Instagram'
import LinkButton from '../../reusable_components/buttons/LinkButton'
import ResponsiveImage from '../../reusable_components/responsive/ResponsiveImage'
import Button from '../../reusable_components/buttons/Button'
import GroupInquiryModalTrigger from '../../reusable_components/modals/group_inquiry/GroupInquiryModalTrigger'
import { ButtonSection } from '../../reusable_components/other/HostSections'
import Linkedin from '../../reusable_components/svg_icons/Linkedin'
import pageScroller from '../../../utils/pageScroller'
import IntersectionObserverWrapper from '../../reusable_components/responsive/IntersectionObserverWrapper'
import ReadMore from '../../reusable_components/responsive/ReadMore'

export const BlackBackground = styled.div`
	padding-top: ${theme.paddings.large};
	padding-bottom: ${theme.paddings.large};
	background: ${theme.colors.black};
	*,
	*:not(button > div):hover {
		color: white;
	}
	${StyledProfilePicture} {
		box-shadow: #000000 1px 1px 9px 0px;
	}
`
const ImageWrapper = styled.div`
	display: flex;
	${theme.breakpoints.tablet.overAndExcluding} {
		justify-content: flex-end;
	}
`
const FollowSection = styled.div`
	display: flex;
	align-items: center;
	> div:last-child {
		margin-left: ${theme.margins.XSmall};
	}
`

const SmWrapper = styled.div`
	margin-top: ${theme.margins.small};
	> p {
		margin-top: ${theme.margins.XSmall};
		display: flex;
		align-items: center;
		> a {
			margin-right: ${theme.margins.XXSmall};
			> svg {
				display: block;
			}
		}
	}
`
const TitleRating = styled.span`
	margin-left: ${theme.margins.XXSmall};
	text-decoration: underline;
	cursor: pointer;
`
const companyUrlShort = companyUrl => {
	if (!companyUrl) return null
	let match = companyUrl.match(/^(?:http|https)?:?(?:\/\/)?(?:www)?\.?([\w-]*\.?[a-z]{2,4}\/?.*)$/i)
	if (match?.length > 1) {
		match = match[1].slice(-1) === '/' ? match[1].slice(0, -1) : match[1]
		return match
	} else {
		return companyUrl
	}
}
const igUrlShort = igUrl => {
	if (!igUrl) return null
	let match = igUrl.match(/^(?:http|https)?:?(?:\/\/)?(?:www)?\.?(?:instagram)?\.?[a-z]{2,4}\/?([\w.-]*)\/?.*$/i)
	if (match?.length > 1) {
		match = match[1].replace(/[_-]/gi, ' ').trim()
		return match
	} else {
		return igUrl
	}
}
const fbUrlShort = fbUrl => {
	if (!fbUrl) return null
	let match = fbUrl.match(/^(?:http|https)?:?(?:\/\/)?(?:www)?\.?(?:facebook)?\.?[a-z]{2,4}\/?([a-z._-]*)[0-9]*\/?.*$/i)
	if (match?.length > 1) {
		match = match[1].replace(/[_-]/gi, ' ').trim()
		return match
	} else {
		return fbUrl
	}
}
const linkedinUrlShort = linkedinUrl => {
	if (!linkedinUrl) return null
	let match = linkedinUrl.match(
		/^(?:http|https)?:?(?:\/\/)?(?:www)?\.?(?:linkedin)?\.?[a-z]{2,4}\/company\/?([a-z._-]*)[0-9]*\/?.*$/i,
	)
	if (match?.length > 1) {
		match = match[1].replace(/[_-]/gi, ' ').trim()
		return match
	} else {
		return linkedinUrl
	}
}
const HostContent = ({ host, ssrCourseCardData, initialCoursesWithDates, isOpenedInContentSideBar = true }) => {
	if (!host) return null
	const {
		id,
		fullName,
		hostId,
		description,
		coverImage,
		expertType,
		reviewData,
		sidebarVideo,
		sidebarVideoText,
		displayNameExtensionHostPage,
		companyTitle,
		companyDescr,
		companyUrl,
		igUrl,
		fbUrl,
		linkedinUrl,
		companyLogo,
		hostPicture,
		showBrandSection,
		hostProducts,
		numberOfFavourites,
		isPreview,
	} = host
	const { t, i18n } = useTranslation()
	const { state, dispatch } = useContext(ContentSideBarContext)
	const whiteLabelIcon = state.whiteLabelIcon
	const hostPageSideBarIsOpen = state.sideBarIsOpen && state.contentType === 'HOST'
	/*const hostHeadline = name => {
		const locale = i18n.language
		if (locale === 'sv' && name.slice(-1) === 's') {
			return `${name} ${t('copy:hostPage.workshops')}`
		} else {
			const suffix = locale === 'en' ? "'s" : 's'
			return `${name}${suffix} ${t('copy:hostPage.workshops')}`
		}
	}*/
	// Scroll to a designated element on the page
	useEffect(() => {
		const url = new URL(location.href)
		if (url.searchParams.get('ps')) {
			pageScroller(url.searchParams.get('ps'))
		}
	}, []);
	const { rating, count } = reviewData
	return (
		<>
			<ContentSlider image={coverImage} title={fullName} isOpenedInContentSideBar={isOpenedInContentSideBar} />
			<BlackBackground>
				<ContentSideBarWrapper isOpenedInContentSideBar={isOpenedInContentSideBar}>
					<ContentRow margins={{ top: 0, bottom: 0 }}>
						<div>
							<FollowSection>
								<ProfilePicture profileName={fullName} width={150} imagePath={hostPicture} />
								<ButtonSection>
									<FollowButton favouritableType="hostPage" favouritableId={id} color="WHITE" />
									<GroupInquiryModalTrigger hostName={fullName} hostId={hostId} modalContent="CONTACT">
										<Button color="OUTLINED_WHITE">{t('common:buttons.contactMe')}</Button>
									</GroupInquiryModalTrigger>
								</ButtonSection>
							</FollowSection>
						</div>
						<div>
							<h1>{fullName + (displayNameExtensionHostPage ? ` | ${displayNameExtensionHostPage}` : '')}</h1>
							<ReadMore maxLines={20}>
								<p>{description}</p>
							</ReadMore>
							<br />
							{!!count && (
								<div style={{ display: 'flex' }}>
									<div onClick={() => pageScroller('COURSE_PAGE_REVIEW_LIST')}>
										★
										<TitleRating>
											{rating}&nbsp;({count})
										</TitleRating>
									</div>
									{numberOfFavourites > 1 &&
										`${count ? ',' : ''}${t('copy:coursePage.favourites', { favourites: numberOfFavourites })}`}
								</div>
							)}
						</div>
					</ContentRow>
				</ContentSideBarWrapper>
			</BlackBackground>
			<ContentSideBarWrapper isOpenedInContentSideBar={isOpenedInContentSideBar} id="hpc">
				{(!isOpenedInContentSideBar || hostPageSideBarIsOpen) && (
					<CourseCardsWrapper
						type="experts"
						id={hostId}
						isPreview={isPreview}
						hideTitle
						paginate
						paginateWithButton
						ssrCourseCardData={ssrCourseCardData}
						quantity={4}
					/>
				)}
				<DatesSection
					hostId={hostId}
					t={t}
					i18n={i18n}
					initialCoursesWithDates={initialCoursesWithDates}
				/>
				{(!isOpenedInContentSideBar || hostPageSideBarIsOpen) && !!count && (
					<div id="COURSE_PAGE_REVIEW_LIST">
						<IntersectionObserverWrapper>
							<HostPageReviewList reviewData={reviewData} hostPageId={id} />
						</IntersectionObserverWrapper>
					</div>
				)}
				{sidebarVideo && (
					<>
						<Divider />
						<ContentRow>
							<VideoFormContainer>
								<iframe src={sidebarVideo} />
							</VideoFormContainer>
							<Markdown>{sidebarVideoText}</Markdown>
						</ContentRow>
					</>
				)}
				{companyTitle && companyDescr && companyLogo && showBrandSection && (
					<>
						<Divider />
						<ContentRow title={companyTitle}>
							<div>
								<p>{companyDescr}</p>
								{companyUrl && (
									<>
										<br />
										<p>
											<a href={companyUrl} target="_blank" rel="noreferrer noopener">
												{companyUrlShort(companyUrl)}
											</a>
										</p>
									</>
								)}
								<SmWrapper>
									{igUrl && (
										<p>
											<a href={igUrl} target="_blank" rel="noreferrer noopener">
												<Instagram />
											</a>
											<a href={igUrl} target="_blank" rel="noreferrer noopener">
												{igUrlShort(igUrl)}
											</a>
										</p>
									)}
									{fbUrl && (
										<p>
											<a href={fbUrl} target="_blank" rel="noreferrer noopener">
												<Facebook />
											</a>
											<a href={fbUrl} target="_blank" rel="noreferrer noopener">
												{fbUrlShort(fbUrl)}
											</a>
										</p>
									)}
									{linkedinUrl && (
										<p>
											<a href={fbUrl} target="_blank" rel="noreferrer noopener">
												<Linkedin height="20px" width="20px" style={{ marginLeft: '2px' }} />
											</a>
											<a href={linkedinUrl} target="_blank" rel="noreferrer noopener">
												{linkedinUrlShort(linkedinUrl)}
											</a>
										</p>
									)}
								</SmWrapper>
							</div>
							<ImageWrapper>
								<a href={companyUrl || null} target="_blank" rel="noreferrer noopener">
									<NonResponsiveImage imagePath={companyLogo} width={200} />
								</a>
							</ImageWrapper>
						</ContentRow>
					</>
				)}
				{hostProducts?.length > 0 && (
					<>
						<SmallPageSectionDivider />
						<ContentRow
							title={t('copy:hostPage.moreFrom', { name: fullName })}
							childrenPerRowDesktop={3}
							margins={{ innerMobile: theme.margins.largeInt }}
						>
							{hostProducts.map(({ description, link, image }, key) => (
								<div key={key}>
									<a href={link} target="_blank" rel="noreferrer noopener">
										<ResponsiveImage imagePath={image} type="BIG_VERTICAL_RECTANGLE" />
									</a>
									<br />
									<p>{description}</p>
									<br />
									<a href={link} target="_blank" rel="noreferrer noopener">
										<LinkButton>{t('common:buttons.seeMore')}</LinkButton>
									</a>
								</div>
							))}
						</ContentRow>
					</>
				)}
			</ContentSideBarWrapper>
			<BeaconAdjuster adjust={hostPageSideBarIsOpen} source="HOST_CONTENT" />
		</>
	)
}

export default HostContent
